import React, { useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import REB_Logo from "../../../assets/REB_Logo.png";
import RTB_Logo from "../../../assets/RTB_Logo.png";

import {
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { connect } from "react-redux";
import { logout } from "../../../../store/common/actions";
import { isEmpty } from "lodash";
import { TreeItem, TreeView } from "@mui/lab";

const DrawerContent = (props) => {
  const {
    drawerState,
    setDrawerState,
    onDrowerItemClicked,
    user,
    logout,
    selectedModule,
    env,
  } = props;

  const location = useLocation();
  const history = useHistory();

  const [expanded, setExpanded] = useState([
    user?.selectedEntity?.role !== "TEACHER" ? "16" : "6",
  ]);

  const handleToggleNode = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  return (
    <React.Fragment>
      <div
        id="drawer"
        className={`position-fixed ${drawerState ? "active" : ""}`}
      >
        <div>
          <div className="drawer-logo">
            {user?.selectedScope?.id === "REB" && (
              <img src={REB_Logo} className="" alt="Linda Miller" />
            )}
            {user?.selectedScope?.id === "RTB" && (
              <img src={RTB_Logo} className="" alt="Linda Miller" />
            )}
          </div>
          <div style={{ marginTop: "0px" }}>
            <div style={{ backgroundColor: "#015198", height: "7px" }}></div>
          </div>
          <Box
            sx={{ height: "73vh", flexGrow: 1, overflowY: "auto" }}
            className="mt-2 pr-2"
          >
            <TreeView
              aria-label="controlled"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              expanded={expanded}
              onNodeToggle={handleToggleNode}
            >
              <TreeItem
                className={`nav-item ${
                  location.pathname.includes("/in/welcome") ? "active" : ""
                }`}
                nodeId="1"
                label={
                  <Link onClick={onDrowerItemClicked} to="/in/welcome">
                    <span
                      className="d-flex justify-content-between  align-items-center"
                      style={{ maxWidth: "100%" }}
                    >
                      <span
                        style={{
                          cursor: "default",
                        }}
                      >
                        Welcome
                      </span>

                      <span>
                        <IconButton
                          disableRipple
                          size="small"
                          className="ml-2"
                          aria-label="settings"
                        >
                          <Badge color="info">
                            <span
                              className="material-icons"
                              style={{ fontSize: "18px" }}
                            >
                              home
                            </span>
                          </Badge>
                        </IconButton>
                      </span>
                    </span>
                  </Link>
                }
              />

              {user?.selectedEntity?.role !== "TEACHER" && (
                <TreeItem
                  className={`nav-item`}
                  nodeId="16"
                  label={
                    <span
                      className="d-flex justify-content-between  align-items-center"
                      style={{ maxWidth: "100%" }}
                    >
                      <span className={`d-block text-truncate`}>
                        <span
                          className="mr-2 menu-header"
                          style={{
                            cursor: "default",
                          }}
                        >
                          School Management
                        </span>
                      </span>

                      <span>
                        <IconButton
                          disableRipple
                          size="small"
                          className="ml-2 menu-header-icon"
                          aria-label="settings"
                        >
                          <Badge color="info">
                            <span
                              className="material-icons"
                              style={{ fontSize: "18px" }}
                            >
                              {" "}
                              gite
                            </span>
                          </Badge>
                        </IconButton>
                      </span>
                    </span>
                  }
                >
                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes("/in/schools/dashboard")
                        ? "active"
                        : ""
                    }`}
                    nodeId="19"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/schools/dashboard"
                      >
                        <span
                          className="d-flex justify-content-between  align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span className={`d-block text-truncate`}>
                            <span
                              className="mr-2"
                              style={{
                                cursor: "default",
                              }}
                            >
                              Dashboard
                            </span>
                          </span>

                          <span>
                            <IconButton
                              disableRipple
                              size="small"
                              className="ml-2"
                              aria-label="settings"
                            >
                              <Badge color="info">
                                <span
                                  className="material-icons"
                                  style={{ fontSize: "18px" }}
                                >
                                  pie_chart
                                </span>
                              </Badge>
                            </IconButton>
                          </span>
                        </span>
                      </Link>
                    }
                  />

                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes("/in/schools/posts")
                        ? "active"
                        : ""
                    }`}
                    nodeId="17"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/schools/posts"
                      >
                        <span
                          className="d-flex justify-content-between  align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span className={`d-block text-truncate`}>
                            <span
                              className="mr-2"
                              style={{
                                cursor: "default",
                              }}
                            >
                              Post Mgmt
                            </span>
                          </span>

                          <span>
                            <IconButton
                              disableRipple
                              size="small"
                              className="ml-2"
                              aria-label="settings"
                            >
                              <Badge color="info">
                                <span
                                  className="material-icons"
                                  style={{ fontSize: "18px" }}
                                >
                                  event_seat
                                </span>
                              </Badge>
                            </IconButton>
                          </span>
                        </span>
                      </Link>
                    }
                  />

                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes("/in/schools/staff-list")
                        ? "active"
                        : ""
                    }`}
                    nodeId="28"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/schools/staff-list"
                      >
                        <span
                          className="d-flex justify-content-between  align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span className={`d-block text-truncate`}>
                            <span
                              className="mr-2"
                              style={{
                                cursor: "default",
                              }}
                            >
                              Staff List
                            </span>
                          </span>

                          <span>
                            <IconButton
                              disableRipple
                              size="small"
                              className="ml-2"
                              aria-label="settings"
                            >
                              <Badge color="info">
                                <span
                                  className="material-icons"
                                  style={{ fontSize: "18px" }}
                                >
                                  people
                                </span>
                              </Badge>
                            </IconButton>
                          </span>
                        </span>
                      </Link>
                    }
                  />
                  {/* {!["TEACHER", "HEAD_TEACHER"].includes(
                    user?.selectedEntity.role
                  ) && ( */}
                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes("/in/schools/post-requests")
                        ? "active"
                        : ""
                    }`}
                    nodeId="14"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/schools/post-requests"
                      >
                        <span
                          className="d-flex justify-content-between  align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span className={`d-block text-truncate`}>
                            <span
                              className="mr-2"
                              style={{
                                cursor: "default",
                              }}
                            >
                              Post requests{" "}
                              {/* <span className="badge badge-primary">New</span> */}
                            </span>
                          </span>

                          <span>
                            <IconButton
                              disableRipple
                              size="small"
                              className="ml-2"
                              aria-label="settings"
                            >
                              <Badge color="info">
                                <span
                                  className="material-icons"
                                  style={{ fontSize: "18px" }}
                                >
                                  add_circle_outline
                                </span>
                              </Badge>
                            </IconButton>
                          </span>
                        </span>
                      </Link>
                    }
                  />
                  {/* )} */}

                  {user?.selectedEntity?.role !== "HEAD_TEACHER" && (
                    <TreeItem
                      className={`nav-item ${
                        location.pathname.includes("/in/schools/distributions")
                          ? "active"
                          : ""
                      }`}
                      nodeId="18"
                      label={
                        <Link
                          onClick={onDrowerItemClicked}
                          to="/in/schools/distributions"
                        >
                          <span
                            className="d-flex justify-content-between  align-items-center"
                            style={{ maxWidth: "100%" }}
                          >
                            <span className={`d-block text-truncate`}>
                              <span
                                className="mr-2"
                                style={{
                                  cursor: "default",
                                }}
                              >
                                Posts distribution
                              </span>
                            </span>

                            <span>
                              <IconButton
                                disableRipple
                                size="small"
                                className="ml-2"
                                aria-label="settings"
                              >
                                <Badge color="info">
                                  <span
                                    className="material-icons"
                                    style={{ fontSize: "18px" }}
                                  >
                                    share
                                  </span>
                                </Badge>
                              </IconButton>
                            </span>
                          </span>
                        </Link>
                      }
                    />
                  )}

                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes("/in/schools/user-guide")
                        ? "active"
                        : ""
                    }`}
                    nodeId="20"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/schools/user-guide"
                      >
                        <span
                          className="d-flex justify-content-between  align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span
                            style={{
                              cursor: "default",
                            }}
                          >
                            User-guide
                          </span>

                          <span>
                            <IconButton
                              disableRipple
                              size="small"
                              className="ml-2"
                              aria-label="settings"
                            >
                              <Badge color="info">
                                <span
                                  className="material-icons"
                                  style={{ fontSize: "18px" }}
                                >
                                  local_library
                                </span>
                              </Badge>
                            </IconButton>
                          </span>
                        </span>
                      </Link>
                    }
                  />
                </TreeItem>
              )}

              <TreeItem
                className={`nav-item`}
                nodeId="6"
                label={
                  <span
                    className="d-flex justify-content-between  align-items-center"
                    style={{ maxWidth: "100%" }}
                  >
                    <span className={`d-block text-truncate`}>
                      <span
                        className="mr-2 menu-header"
                        style={{
                          cursor: "default",
                        }}
                      >
                        Transfers & Permutations
                      </span>
                    </span>

                    <span>
                      <IconButton
                        disableRipple
                        size="small"
                        className="ml-2 menu-header-icon"
                        aria-label="settings"
                      >
                        <Badge color="info">
                          <span
                            className="material-icons"
                            style={{ fontSize: "18px" }}
                          >
                            change_circle
                          </span>
                        </Badge>
                      </IconButton>
                    </span>
                  </span>
                }
              >
                {/* {env !== "prod" && user.selectedEntity.role !== "TEACHER" && (
                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes("/in/transfers/dashboard")
                        ? "active"
                        : ""
                    }`}
                    nodeId="10"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/transfers/dashboard"
                      >
                        <span
                          className="d-flex justify-content-between  align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span className={`d-block text-truncate`}>
                            <span
                              className="mr-2"
                              style={{
                                cursor: "default",
                              }}
                            >
                              Dashboard
                            </span>
                          </span>

                          <span>
                            <IconButton
                              disableRipple
                              size="small"
                              className="ml-2"
                              aria-label="settings"
                            >
                              <Badge color="info">
                                <span
                                  className="material-icons"
                                  style={{ fontSize: "18px" }}
                                >
                                  pie_chart
                                </span>
                              </Badge>
                            </IconButton>
                          </span>
                        </span>
                      </Link>
                    }
                  />
                )} */}

                <TreeItem
                  className={`nav-item ${
                    location.pathname.includes("/in/transfers/permutations")
                      ? "active"
                      : ""
                  }`}
                  nodeId="9"
                  label={
                    <Link
                      onClick={onDrowerItemClicked}
                      to="/in/transfers/permutations"
                    >
                      <span
                        className="d-flex justify-content-between align-items-center"
                        style={{ maxWidth: "100%" }}
                      >
                        <span className={`d-block text-truncate`}>
                          <span
                            className="mr-2"
                            style={{
                              cursor: "default",
                            }}
                          >
                            Permutations
                          </span>
                        </span>

                        <span>
                          <IconButton
                            disableRipple
                            size="small"
                            className="ml-2"
                            aria-label="settings"
                          >
                            <Badge color="info">
                              <span
                                className="material-icons"
                                style={{ fontSize: "18px" }}
                              >
                                sync
                              </span>
                            </Badge>
                          </IconButton>
                        </span>
                      </span>
                    </Link>
                  }
                />

                <TreeItem
                  className={`nav-item ${
                    location.pathname.includes("/in/transfers/requests")
                      ? "active"
                      : ""
                  }`}
                  nodeId="8"
                  label={
                    <Link
                      onClick={onDrowerItemClicked}
                      to="/in/transfers/requests"
                    >
                      <span
                        className="d-flex justify-content-between  align-items-center"
                        style={{ maxWidth: "100%" }}
                      >
                        <span className={`d-block text-truncate`}>
                          <span
                            className="mr-2"
                            style={{
                              cursor: "default",
                            }}
                          >
                            Transfers
                          </span>
                        </span>

                        <span>
                          <IconButton
                            disableRipple
                            size="small"
                            className="ml-2"
                            aria-label="settings"
                          >
                            <Badge color="info">
                              <span
                                className="material-icons"
                                style={{ fontSize: "18px" }}
                              >
                                list_alt
                              </span>
                            </Badge>
                          </IconButton>
                        </span>
                      </span>
                    </Link>
                  }
                />

                <TreeItem
                  className={`nav-item ${
                    location.pathname.includes("/in/transfers/user-guide")
                      ? "active"
                      : ""
                  }`}
                  nodeId="31"
                  label={
                    <Link
                      onClick={onDrowerItemClicked}
                      to="/in/transfers/user-guide"
                    >
                      <span
                        className="d-flex justify-content-between align-items-center"
                        style={{ maxWidth: "100%" }}
                      >
                        <span className={`d-block text-truncate`}>
                          <span
                            className="mr-2"
                            style={{
                              cursor: "default",
                            }}
                          >
                            User-guide
                          </span>
                        </span>

                        <span>
                          <IconButton
                            disableRipple
                            size="small"
                            className="ml-2"
                            aria-label="settings"
                          >
                            <Badge color="info">
                              <span
                                className="material-icons"
                                style={{ fontSize: "18px" }}
                              >
                                local_library
                              </span>
                            </Badge>
                          </IconButton>
                        </span>
                      </span>
                    </Link>
                  }
                />
              </TreeItem>

              {user?.selectedEntity?.role !== "TEACHER" && (
                <TreeItem
                  className={`nav-item`}
                  nodeId="11"
                  label={
                    <span
                      className="d-flex justify-content-between  align-items-center"
                      style={{ maxWidth: "100%" }}
                    >
                      <span className={`d-block text-truncate`}>
                        <span
                          className="mr-2 menu-header"
                          style={{
                            cursor: "default",
                          }}
                        >
                          Placement reports
                        </span>
                      </span>

                      <span>
                        <IconButton
                          disableRipple
                          size="small"
                          className="ml-2 menu-header-icon"
                          aria-label="settings"
                        >
                          <Badge color="info">
                            <span
                              className="material-icons"
                              style={{ fontSize: "18px" }}
                            >
                              approval
                            </span>
                          </Badge>
                        </IconButton>
                      </span>
                    </span>
                  }
                >
                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes(
                        "/in/placement/new-placement-reports"
                      )
                        ? "active"
                        : ""
                    }`}
                    nodeId="35"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/placement/new-placement-reports"
                      >
                        <span
                          className="d-flex justify-content-between  align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span className={`d-block text-truncate`}>
                            <span
                              className="mr-2"
                              style={{
                                cursor: "default",
                              }}
                            >
                              New Reports
                            </span>
                          </span>

                          <span>
                            <IconButton
                              disableRipple
                              size="small"
                              className="ml-2"
                              aria-label="settings"
                            >
                              <Badge color="info">
                                <span
                                  className="material-icons"
                                  style={{
                                    transform: "rotateY(180deg)",
                                    fontSize: "18px",
                                  }}
                                >
                                  people_alt
                                </span>
                              </Badge>
                            </IconButton>
                          </span>
                        </span>
                      </Link>
                    }
                  />

                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes(
                        "/in/placement/archived-placement-reports"
                      )
                        ? "active"
                        : ""
                    }`}
                    nodeId="13"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/placement/archived-placement-reports"
                      >
                        <span
                          className="d-flex justify-content-between  align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span className={`d-block text-truncate`}>
                            <span
                              className="mr-2"
                              style={{
                                cursor: "default",
                              }}
                            >
                              Archived Reports
                            </span>
                          </span>

                          <span>
                            <IconButton
                              disableRipple
                              size="small"
                              className="ml-2"
                              aria-label="settings"
                            >
                              <Badge color="info">
                                <span
                                  className="material-icons"
                                  style={{
                                    transform: "rotateY(180deg)",
                                    fontSize: "18px",
                                  }}
                                >
                                  reply_all
                                </span>
                              </Badge>
                            </IconButton>
                          </span>
                        </span>
                      </Link>
                    }
                  />

                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes("/in/placement/unsubmitted")
                        ? "active"
                        : ""
                    }`}
                    nodeId="15"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/placement/unsubmitted"
                      >
                        <span
                          className="d-flex justify-content-between  align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span className={`d-block text-truncate text-danger`}>
                            <span
                              className="mr-2"
                              style={{
                                cursor: "default",
                              }}
                            >
                              Unsubmitted
                            </span>
                          </span>

                          <span>
                            <IconButton
                              color="error"
                              disableRipple
                              size="small"
                              className="ml-2"
                              aria-label="settings"
                            >
                              <Badge color="info">
                                <span
                                  className="material-icons"
                                  style={{ fontSize: "18px" }}
                                >
                                  warning
                                </span>
                              </Badge>
                            </IconButton>
                          </span>
                        </span>
                      </Link>
                    }
                  />

                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes("/in/placement/user-guide")
                        ? "active"
                        : ""
                    }`}
                    nodeId="30"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/placement/user-guide"
                      >
                        <span
                          className="d-flex justify-content-between  align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span className={`d-block text-truncate`}>
                            <span
                              className="mr-2"
                              style={{
                                cursor: "default",
                              }}
                            >
                              User-guide
                            </span>
                          </span>

                          <span>
                            <IconButton
                              disableRipple
                              size="small"
                              className="ml-2"
                              aria-label="settings"
                            >
                              <Badge color="info">
                                <span
                                  className="material-icons"
                                  style={{ fontSize: "18px" }}
                                >
                                  local_library
                                </span>
                              </Badge>
                            </IconButton>
                          </span>
                        </span>
                      </Link>
                    }
                  />
                </TreeItem>
              )}

              {(user?.selectedEntity?.role !== "TEACHER" ||
                user.gender === "F") &&
                user?.selectedScope.id !== "RTB" && (
                  <TreeItem
                    className={`nav-item`}
                    nodeId="33"
                    label={
                      <span
                        className="d-flex justify-content-between align-items-center"
                        style={{ maxWidth: "100%" }}
                      >
                        <span className={`d-block text-truncate`}>
                          <span
                            className="mr-2 menu-header"
                            style={{
                              cursor: "default",
                            }}
                          >
                            Maternity Leave
                          </span>
                        </span>

                        <span>
                          <IconButton
                            disableRipple
                            size="small"
                            className="ml-2 menu-header-icon"
                            aria-label="settings"
                          >
                            <Badge color="info">
                              <span
                                className="material-icons"
                                style={{ fontSize: "18px" }}
                              >
                                psychology
                              </span>
                            </Badge>
                          </IconButton>
                        </span>
                      </span>
                    }
                  >
                    {/* {env !== "prod" && (
                    <TreeItem
                      className={`nav-item ${
                        location.pathname.includes(
                          "/in/maternity-leave/dashboard"
                        )
                          ? "active"
                          : ""
                      }`}
                      nodeId="34"
                      label={
                        <Link
                          onClick={onDrowerItemClicked}
                          to="/in/maternity-leave/dashboard"
                        >
                          <span
                            className="d-flex justify-content-between  align-items-center"
                            style={{ maxWidth: "100%" }}
                          >
                            <span className={`d-block text-truncate`}>
                              <span
                                className="mr-2"
                                style={{
                                  cursor: "default",
                                }}
                              >
                                Dashboard
                              </span>
                            </span>

                            <span>
                              <IconButton
                                disableRipple
                                size="small"
                                className="ml-2"
                                aria-label="settings"
                              >
                                <Badge color="info">
                                  <span
                                    className="material-icons"
                                    style={{ fontSize: "18px" }}
                                  >
                                    pie_chart
                                  </span>
                                </Badge>
                              </IconButton>
                            </span>
                          </span>
                        </Link>
                      }
                    />
                  )} */}

                    <TreeItem
                      className={`nav-item ${
                        location.pathname.includes(
                          "/in/maternity-leave/in-progress"
                        )
                          ? "active"
                          : ""
                      }`}
                      nodeId="36"
                      label={
                        <Link
                          onClick={onDrowerItemClicked}
                          to="/in/maternity-leave/in-progress"
                        >
                          <span
                            className="d-flex justify-content-between  align-items-center"
                            style={{ maxWidth: "100%" }}
                          >
                            <span className={`d-block text-truncate`}>
                              <span
                                className="mr-2"
                                style={{
                                  cursor: "default",
                                }}
                              >
                                New requests
                              </span>
                            </span>

                            <span>
                              <IconButton
                                disableRipple
                                size="small"
                                className="ml-2"
                                aria-label="settings"
                              >
                                <Badge color="info">
                                  <span
                                    className="material-icons"
                                    style={{ fontSize: "18px" }}
                                  >
                                    schedule
                                  </span>
                                </Badge>
                              </IconButton>
                            </span>
                          </span>
                        </Link>
                      }
                    />
                    <TreeItem
                      className={`nav-item ${
                        location.pathname.includes(
                          "/in/maternity-leave/archived"
                        )
                          ? "active"
                          : ""
                      }`}
                      nodeId="37"
                      label={
                        <Link
                          onClick={onDrowerItemClicked}
                          to="/in/maternity-leave/archived"
                        >
                          <span
                            className="d-flex justify-content-between  align-items-center"
                            style={{ maxWidth: "100%" }}
                          >
                            <span className={`d-block text-truncate`}>
                              <span
                                className="mr-2"
                                style={{
                                  cursor: "default",
                                }}
                              >
                                Archived
                              </span>
                            </span>

                            <span>
                              <IconButton
                                disableRipple
                                size="small"
                                className="ml-2"
                                aria-label="settings"
                              >
                                <Badge color="info">
                                  <span
                                    className="material-icons"
                                    style={{ fontSize: "18px" }}
                                  >
                                    archive
                                  </span>
                                </Badge>
                              </IconButton>
                            </span>
                          </span>
                        </Link>
                      }
                    />

                    {user?.selectedEntity?.role !== "TEACHER" && (
                      <TreeItem
                        className={`nav-item ${
                          location.pathname.includes(
                            "/in/maternity-leave/replacing-staff"
                          )
                            ? "active"
                            : ""
                        }`}
                        nodeId="38"
                        label={
                          <Link
                            onClick={onDrowerItemClicked}
                            to="/in/maternity-leave/replacing-staff"
                          >
                            <span
                              className="d-flex justify-content-between  align-items-center"
                              style={{ maxWidth: "100%" }}
                            >
                              <span className={`d-block text-truncate`}>
                                <span
                                  className="mr-2"
                                  style={{
                                    cursor: "default",
                                  }}
                                >
                                  Replacing staff
                                </span>
                              </span>

                              <span>
                                <IconButton
                                  disableRipple
                                  size="small"
                                  className="ml-2"
                                  aria-label="settings"
                                >
                                  <Badge color="info">
                                    <span
                                      className="material-icons"
                                      style={{ fontSize: "18px" }}
                                    >
                                      sync
                                    </span>
                                  </Badge>
                                </IconButton>
                              </span>
                            </span>
                          </Link>
                        }
                      />
                    )}

                    <TreeItem
                      className={`nav-item ${
                        location.pathname.includes(
                          "/in/maternity-leave/user-guide"
                        )
                          ? "active"
                          : ""
                      }`}
                      nodeId="39"
                      label={
                        <Link
                          onClick={onDrowerItemClicked}
                          to="/in/maternity-leave/user-guide"
                        >
                          <span
                            className="d-flex justify-content-between  align-items-center"
                            style={{ maxWidth: "100%" }}
                          >
                            <span
                              style={{
                                cursor: "default",
                              }}
                            >
                              User-guide
                            </span>

                            <span>
                              <IconButton
                                disableRipple
                                size="small"
                                className="ml-2"
                                aria-label="settings"
                              >
                                <Badge color="info">
                                  <span
                                    className="material-icons"
                                    style={{ fontSize: "18px" }}
                                  >
                                    local_library
                                  </span>
                                </Badge>
                              </IconButton>
                            </span>
                          </span>
                        </Link>
                      }
                    />
                  </TreeItem>
                )}

              {env !== "prod" && (
                <TreeItem
                  className={`nav-item`}
                  nodeId="21"
                  label={
                    <span
                      className="d-flex justify-content-between align-items-center"
                      style={{ maxWidth: "100%" }}
                    >
                      <span className={`d-block text-truncate`}>
                        <span
                          className="mr-2 menu-header"
                          style={{
                            cursor: "default",
                          }}
                        >
                          CPD Module
                        </span>
                      </span>

                      <span>
                        <IconButton
                          disableRipple
                          size="small"
                          className="ml-2 menu-header-icon"
                          aria-label="settings"
                        >
                          <Badge color="info">
                            <span
                              className="material-icons"
                              style={{ fontSize: "18px" }}
                            >
                              psychology
                            </span>
                          </Badge>
                        </IconButton>
                      </span>
                    </span>
                  }
                >
                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes("/in/cpd/dashboard")
                        ? "active"
                        : ""
                    }`}
                    nodeId="24"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/cpd/dashboard"
                      >
                        <span
                          className="d-flex justify-content-between  align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span className={`d-block text-truncate`}>
                            <span
                              className="mr-2"
                              style={{
                                cursor: "default",
                              }}
                            >
                              Dashboard
                            </span>
                          </span>

                          <span>
                            <IconButton
                              disableRipple
                              size="small"
                              className="ml-2"
                              aria-label="settings"
                            >
                              <Badge color="info">
                                <span
                                  className="material-icons"
                                  style={{ fontSize: "18px" }}
                                >
                                  pie_chart
                                </span>
                              </Badge>
                            </IconButton>
                          </span>
                        </span>
                      </Link>
                    }
                  />

                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes(
                        "/in/cpd/in-progress-trainings"
                      )
                        ? "active"
                        : ""
                    }`}
                    nodeId="22"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/cpd/in-progress-trainings"
                      >
                        <span
                          className="d-flex justify-content-between  align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span className={`d-block text-truncate`}>
                            <span
                              className="mr-2"
                              style={{
                                cursor: "default",
                              }}
                            >
                              InProgress
                            </span>
                          </span>

                          <span>
                            <IconButton
                              disableRipple
                              size="small"
                              className="ml-2"
                              aria-label="settings"
                            >
                              <Badge color="info">
                                <span
                                  className="material-icons"
                                  style={{ fontSize: "18px" }}
                                >
                                  schedule
                                </span>
                              </Badge>
                            </IconButton>
                          </span>
                        </span>
                      </Link>
                    }
                  />
                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes("/in/cpd/archived-trainings")
                        ? "active"
                        : ""
                    }`}
                    nodeId="23"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/cpd/archived-trainings"
                      >
                        <span
                          className="d-flex justify-content-between  align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span className={`d-block text-truncate`}>
                            <span
                              className="mr-2"
                              style={{
                                cursor: "default",
                              }}
                            >
                              Archived
                            </span>
                          </span>

                          <span>
                            <IconButton
                              disableRipple
                              size="small"
                              className="ml-2"
                              aria-label="settings"
                            >
                              <Badge color="info">
                                <span
                                  className="material-icons"
                                  style={{ fontSize: "18px" }}
                                >
                                  archive
                                </span>
                              </Badge>
                            </IconButton>
                          </span>
                        </span>
                      </Link>
                    }
                  />

                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes("/in/cpd/user-guide")
                        ? "active"
                        : ""
                    }`}
                    nodeId="32"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/cpd/user-guide"
                      >
                        <span
                          className="d-flex justify-content-between  align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span
                            style={{
                              cursor: "default",
                            }}
                          >
                            User-guide
                          </span>

                          <span>
                            <IconButton
                              disableRipple
                              size="small"
                              className="ml-2"
                              aria-label="settings"
                            >
                              <Badge color="info">
                                <span
                                  className="material-icons"
                                  style={{ fontSize: "18px" }}
                                >
                                  local_library
                                </span>
                              </Badge>
                            </IconButton>
                          </span>
                        </span>
                      </Link>
                    }
                  />
                </TreeItem>
              )}

              {user?.selectedScope.id !== "RTB" && (
                <TreeItem
                  className={`nav-item ${
                    location.pathname.includes("/in/scholarships")
                      ? "active"
                      : ""
                  }`}
                  nodeId="12"
                  label={
                    <Link onClick={onDrowerItemClicked} to="/in/scholarships">
                      <span
                        className="d-flex justify-content-between  align-items-center"
                        style={{ maxWidth: "100%" }}
                      >
                        <span
                          style={{
                            cursor: "default",
                          }}
                        >
                          Scholarships{" "}
                        </span>

                        <span>
                          <IconButton
                            disableRipple
                            size="small"
                            className="ml-2"
                            aria-label="settings"
                          >
                            <Badge color="info">
                              <span
                                className="material-icons"
                                style={{ fontSize: "18px" }}
                              >
                                school
                              </span>
                            </Badge>
                          </IconButton>
                        </span>
                      </span>
                    </Link>
                  }
                />
              )}

              {/* 

              <TreeItem
                className={`nav-item`}
                nodeId="25"
                label={
                  <span
                    className="d-flex justify-content-between  align-items-center"
                    style={{ maxWidth: "100%" }}
                  >
                    <span className={`d-block text-truncate`}>
                      <span
                        className="mr-2 menu-header"
                        style={{
                          cursor: "default",
                        }}
                      >
                        Licenses
                      </span>
                    </span>

                    <span>
                      <IconButton
                        disableRipple
                        size="small"
                        className="ml-2 menu-header-icon"
                        aria-label="settings"
                      >
                        <Badge color="info">
                          <span
                            className="material-icons"
                            style={{ fontSize: "18px" }}
                          >
                            psychology
                          </span>
                        </Badge>
                      </IconButton>
                    </span>
                  </span>
                }
              >
                <TreeItem
                  className={`nav-item ${
                    location.pathname.includes("/in/licenses/dashboard")
                      ? "active"
                      : ""
                  }`}
                  nodeId="29"
                  label={
                    <Link
                      onClick={onDrowerItemClicked}
                      to="/in/licenses/dashboard"
                    >
                      <span
                        className="d-flex justify-content-between  align-items-center"
                        style={{ maxWidth: "100%" }}
                      >
                        <span className={`d-block text-truncate`}>
                          <span
                            className="mr-2"
                            style={{
                              cursor: "default",
                            }}
                          >
                            Dashboard
                          </span>
                        </span>

                        <span>
                          <IconButton
                            disableRipple
                            size="small"
                            className="ml-2"
                            aria-label="settings"
                          >
                            <Badge color="info">
                              <span
                                className="material-icons"
                                style={{ fontSize: "18px" }}
                              >
                                pie_chart
                              </span>
                            </Badge>
                          </IconButton>
                        </span>
                      </span>
                    </Link>
                  }
                />

                <TreeItem
                  className={`nav-item ${
                    location.pathname.includes("/in/licenses/new-requests")
                      ? "active"
                      : ""
                  }`}
                  nodeId="26"
                  label={
                    <Link
                      onClick={onDrowerItemClicked}
                      to="/in/licenses/new-requests"
                    >
                      <span
                        className="d-flex justify-content-between  align-items-center"
                        style={{ maxWidth: "100%" }}
                      >
                        <span className={`d-block text-truncate`}>
                          <span
                            className="mr-2"
                            style={{
                              cursor: "default",
                            }}
                          >
                            New requests
                          </span>
                        </span>

                        <span>
                          <IconButton
                            disableRipple
                            size="small"
                            className="ml-2"
                            aria-label="settings"
                          >
                            <Badge color="info">
                              <span
                                className="material-icons"
                                style={{ fontSize: "18px" }}
                              >
                                schedule
                              </span>
                            </Badge>
                          </IconButton>
                        </span>
                      </span>
                    </Link>
                  }
                />
                <TreeItem
                  className={`nav-item ${
                    location.pathname.includes("/in/licenses/offered")
                      ? "active"
                      : ""
                  }`}
                  nodeId="27"
                  label={
                    <Link
                      onClick={onDrowerItemClicked}
                      to="/in/licenses/offered"
                    >
                      <span
                        className="d-flex justify-content-between  align-items-center"
                        style={{ maxWidth: "100%" }}
                      >
                        <span className={`d-block text-truncate`}>
                          <span
                            className="mr-2"
                            style={{
                              cursor: "default",
                            }}
                          >
                            All offered
                          </span>
                        </span>

                        <span>
                          <IconButton
                            disableRipple
                            size="small"
                            className="ml-2"
                            aria-label="settings"
                          >
                            <Badge color="info">
                              <span
                                className="material-icons"
                                style={{ fontSize: "18px" }}
                              >
                                verified
                              </span>
                            </Badge>
                          </IconButton>
                        </span>
                      </span>
                    </Link>
                  }
                />

                <TreeItem
                  className={`nav-item ${
                    location.pathname.includes("/in/licenses/user-guide")
                      ? "active"
                      : ""
                  }`}
                  nodeId="32"
                  label={
                    <Link
                      onClick={onDrowerItemClicked}
                      to="/in/licenses/user-guide"
                    >
                      <span
                        className="d-flex justify-content-between  align-items-center"
                        style={{ maxWidth: "100%" }}
                      >
                        <span
                          style={{
                            cursor: "default",
                          }}
                        >
                          User-guide
                        </span>

                        <span>
                          <IconButton
                            disableRipple
                            size="small"
                            className="ml-2"
                            aria-label="settings"
                          >
                            <Badge color="info">
                              <span
                                className="material-icons"
                                style={{ fontSize: "18px" }}
                              >
                                local_library
                              </span>
                            </Badge>
                          </IconButton>
                        </span>
                      </span>
                    </Link>
                  }
                />
              </TreeItem> */}

              {["ADMIN", "DDE", "REB", "RTB"].includes(
                user?.selectedEntity?.role
              ) && (
                <TreeItem
                  className={`nav-item ${
                    location.pathname.includes("/in/security/system-users")
                      ? "active"
                      : ""
                  }`}
                  nodeId="5"
                  label={
                    <Link
                      onClick={onDrowerItemClicked}
                      to="/in/security/system-users"
                    >
                      <span
                        className="d-flex justify-content-between  align-items-center"
                        style={{ maxWidth: "100%" }}
                      >
                        <span
                          style={{
                            cursor: "default",
                          }}
                        >
                          System Users
                        </span>

                        <span>
                          <IconButton
                            disableRipple
                            size="small"
                            className="ml-2"
                            aria-label="settings"
                          >
                            <Badge color="info">
                              <span
                                className="material-icons"
                                style={{ fontSize: "18px" }}
                              >
                                people
                              </span>
                            </Badge>
                          </IconButton>
                        </span>
                      </span>
                    </Link>
                  }
                />
              )}

              {(user?.selectedEntity?.role === "TEACHER" ||
                user?.selectedEntity?.role === "HEAD_TEACHER") && (
                <TreeItem
                  className={`nav-item mt-4 ${
                    location.pathname.includes("/in/security/my-profile")
                      ? "active"
                      : ""
                  }`}
                  nodeId="100"
                  label={
                    <Link
                      onClick={onDrowerItemClicked}
                      to="/in/security/my-profile"
                    >
                      <span
                        className="d-flex justify-content-between  align-items-center"
                        style={{ maxWidth: "100%" }}
                      >
                        <span
                          style={{
                            cursor: "default",
                          }}
                        >
                          My Profile
                        </span>

                        <span>
                          <IconButton
                            disableRipple
                            size="small"
                            className="ml-2"
                            aria-label="settings"
                          >
                            <Badge color="info">
                              <span
                                className="material-icons"
                                style={{ fontSize: "18px" }}
                              >
                                account_circle
                              </span>
                            </Badge>
                          </IconButton>
                        </span>
                      </span>
                    </Link>
                  }
                />
              )}

              {["REB", "RTB"].includes(user?.selectedEntity?.role) && (
                <TreeItem
                  className={`nav-item mt-1 ${
                    location.pathname.includes("/in/security/global-settings")
                      ? "active"
                      : ""
                  }`}
                  nodeId="102"
                  label={
                    <Link
                      onClick={onDrowerItemClicked}
                      to="/in/security/global-settings"
                    >
                      <span
                        className="d-flex justify-content-between  align-items-center text-dark"
                        style={{ maxWidth: "100%" }}
                      >
                        <span
                          style={{
                            cursor: "default",
                          }}
                        >
                          Global settings
                        </span>

                        <span>
                          <IconButton
                            disableRipple
                            size="small"
                            className="ml-2"
                            aria-label="settings"
                          >
                            <Badge color="info">
                              <span
                                className="material-icons"
                                style={{ fontSize: "18px" }}
                              >
                                settings
                              </span>
                            </Badge>
                          </IconButton>
                        </span>
                      </span>
                    </Link>
                  }
                />
              )}
            </TreeView>
          </Box>
        </div>
        {/* <Button
          className="btn btn-block mb-3 "
          color="error"
          // style={{ position: "absolute", bottom: "0" }}
          onClick={() => logout(history)}
        >
          <i
            className="fas fa-sign-out-alt mr-2 "
            style={{ transform: "rotate(180deg)" }}
          ></i>
          Logout
        </Button> */}
        <div className="text-center pt-3">
          <span>TMIS V.2.1.0</span>
        </div>
      </div>

      <div
        id="drawer-void"
        className={`position-fixed ${
          drawerState ? "d-block d-lg-none" : "d-none"
        } `}
        onClick={() => {
          setDrawerState(false);
          onDrowerItemClicked();
        }}
      ></div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ user, selectedModule, env }) => {
  return { user, selectedModule, env };
};

export default connect(mapStateToProps, { logout })(DrawerContent);
