import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { showError } from "../../toastify";

import {
  login,
  // resetPassword,
} from "../../../store/common/actions";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";

const Login = (props) => {
  const {
    loading,
    login,
    // resetPassword,
    showLoginDialog,
    setShowLoginDialog,
  } = props;

  const history = useHistory();

  const [forgotPassword, setForgotPassword] = useState(false);

  const [errors, setErrors] = useState({
    emailHasError: false,
    passwordHasError: false,
    hasError: false,
  });

  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    showPassword: false,
  });

  const formValidator = () => {
    const error = {
      emailHasError: false,
      passwordHasError: false,
      hasError: false,
    };

    if (!credentials.email) {
      error.emailHasError = true;
      error.hasError = true;
    }

    if (!credentials.password) {
      error.passwordHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const onLogin = async (e) => {
    e.preventDefault();
    if (!formValidator())
      login(
        { email: credentials.email, password: credentials.password },
        history
      );
  };

  const [onWait, setOnWait] = useState(false);
  const [wasReset, setWasReset] = useState(false);

  const resetPassword = async () => {
    try {
      setOnWait(true);
      setWasReset(false);
      await axios.post("/api/auth/reset-password", {
        phone: credentials.email,
      });
      setOnWait(false);
      setWasReset(true);
    } catch (error) {
      setOnWait(false);
      setWasReset(false);
      showError(error);
    }
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      onClose={() => {
        setShowLoginDialog(false);
      }}
      open={showLoginDialog}
    >
      <DialogTitle className={`text-primary`} component="div">
        {!forgotPassword && (
          <h1 className="h3 my-3 font-weight-bold text-primary text-center">
            TMIS Login
          </h1>
        )}

        {forgotPassword && (
          <h1 className="h3 my-3 font-weight-bold text-primary text-center">
            Password reset
          </h1>
        )}
        <IconButton
          aria-label="close"
          onClick={() => {
            setShowLoginDialog(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div style={{ height: "37vh" }} className="text-center">
          {!forgotPassword && (
            <div className="bg-white text-left">
              <form onSubmit={onLogin}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className="mb-3 mt-2 bg-white"
                >
                  <InputLabel htmlFor="emailOrPhone">
                    Enter your phone/email
                  </InputLabel>
                  <OutlinedInput
                    autoFocus
                    id="emailOrPhone"
                    name="emailOrPhone"
                    type="text"
                    value={credentials.email}
                    onChange={(e) => {
                      setCredentials({ ...credentials, email: e.target.value });

                      setErrors({ ...errors, emailHasError: false });
                    }}
                    label="Enter Your ehone/email"
                    placeholder="Enter Your ehone/email"
                  />
                  {errors.emailHasError && (
                    <small className="text-danger mb-1 ">
                      Phone/email is required
                    </small>
                  )}
                </FormControl>

                <FormControl fullWidth variant="outlined" className=" bg-white">
                  <InputLabel htmlFor="password">
                    Enter your password
                  </InputLabel>
                  <OutlinedInput
                    id="password"
                    placeholder="Enter your password"
                    name="password"
                    type={credentials.showPassword ? "text" : "password"}
                    value={credentials.password}
                    onChange={(e) => {
                      setCredentials({
                        ...credentials,
                        password: e.target.value,
                      });

                      setErrors({ ...errors, passwordHasError: false });
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setCredentials({
                              ...credentials,
                              showPassword: !credentials.showPassword,
                            })
                          }
                          edge="end"
                        >
                          {credentials.showPassword ? (
                            <span className="material-icons">
                              visibility_off
                            </span>
                          ) : (
                            <span className="material-icons">visibility</span>
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Enter Your Password"
                  />
                  {errors.passwordHasError && (
                    <small className="text-danger mb-1 ">
                      Password is required
                    </small>
                  )}
                </FormControl>
                <div className="text-right">
                  <button
                    type="button"
                    onClick={() => setForgotPassword(true)}
                    className="btn btn-link mb-3 pr-0"
                  >
                    Forgot password?
                  </button>
                </div>

                {!loading && (
                  <Button
                    color="warning"
                    type="submit"
                    disabled={loading}
                    variant="contained"
                    disableElevation
                    className="btn btn-block text-light mt-3"
                    size="large"
                  >
                    Login
                  </Button>
                )}

                {loading && (
                  <Button
                    color="warning"
                    type="button"
                    variant="contained"
                    disableElevation
                    className="btn btn-block text-light mt-3"
                    size="large"
                  >
                    Connecting...
                  </Button>
                )}

                <div className="text-center mt-4">
                  {/* <Button
                    type="button"
                    onClick={() => history.push("/")}
                    className="mb-3"
                  >
                    <span className="material-icons">
                      keyboard_double_arrow_left
                    </span>{" "}
                    Back To Home
                  </Button> */}
                </div>
              </form>
            </div>
          )}
          {forgotPassword && (
            <div className=" bg-white">
              {wasReset && (
                <Alert icon={false} severity="success" className="mb-3">
                  <AlertTitle className="d-flex justify-content-center">
                    {" "}
                    <span className="material-icons mr-1">smartphone</span>{" "}
                    Check your phone.
                  </AlertTitle>
                  The new password was sent to your phone.
                </Alert>
              )}

              {!wasReset && (
                <>
                  <TextField
                    fullWidth
                    autoFocus
                    name="phone"
                    type="number"
                    variant="outlined"
                    label="Enter your phone number"
                    placeholder="07XXXXXXXX"
                    className="mt-2 mb-3 bg-white"
                    value={credentials.email}
                    onChange={(e) => {
                      setCredentials({
                        ...credentials,
                        email: e.target.value || "",
                      });
                    }}
                  />

                  {!onWait && (
                    <Button
                      disabled={
                        !credentials.email || credentials.email.length !== 10
                      }
                      color="warning"
                      type="button"
                      variant="contained"
                      disableElevation
                      className="btn btn-block text-light"
                      size="large"
                      onClick={() => resetPassword()}
                    >
                      Get New Password
                    </Button>
                  )}

                  {onWait && (
                    <Button
                      color="warning"
                      type="button"
                      variant="contained"
                      disableElevation
                      className="btn btn-block text-light"
                      size="large"
                      onClick={() => resetPassword()}
                    >
                      Connecting...
                    </Button>
                  )}
                </>
              )}

              <Button
                type="button"
                onClick={() => {
                  setForgotPassword(false);
                  setOnWait(false);
                  setWasReset(false);
                }}
                className="pr-3 my-3"
              >
                <span className="material-icons">
                  keyboard_double_arrow_left
                </span>{" "}
                Back to Login
              </Button>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions className="d-flex justify-content-center py-4"></DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ user, loading }) => {
  return { user, loading };
};
export default connect(mapStateToProps, {
  login,
})(Login);
